@use './helios-light' as defaultTheme;

// private vars
$_palette: defaultTheme.$palette;
$_main: defaultTheme.$main;
$_shades: defaultTheme.$shades;
$_elevations: defaultTheme.$elevations;

@mixin config($palette: null, $main: null, $shades: null, $elevations: null) {
    @if $palette {
        $_palette: $palette !global;
    }
    @if $main {
        $_main: $main !global;
    }
    @if $shades {
        $_shades: $shades !global;
    }
    @if $elevations {
        $_elevations: $elevations !global;
    }
};

/// CSS variables generator
/// ex: --primary-5, --shade-10, --brand, etc...
/// These are GLOBAL TOKENS, they can be used in any scss file via theme-color() function
/// theme-color(--primary), theme-color(--shade-10), theme-color(--elevation-1)
/// @access public
@mixin variables() {
    /*
    Generated color tokens based on palette color map
    These tokens are in fact rgb codes (not colors) in order to be usable in rgb/rgba color functions
    Basicaly to support opacity (see top section, component tokens for examples)
    */
    @each $color, $hex in $_palette {
        --#{$color}: #{red($hex), green($hex), blue($hex)};
    }

    /*
    Generated main tokens based on main color map
    */
    @each $color, $hex in $_main {
        --#{$color}: #{red($hex), green($hex), blue($hex)};
    }

    /*
    Generated color tokens based on shade color map
    */
    @each $shade, $value in $_shades {
        --#{$shade}: #{$value};
    }

    /*
    Generated elevation tokens based on elevation color map
    */
    @each $elevation, $value in $_elevations {
        --#{$elevation}: #{$value};
    }
}

/// Color class generator
/// ex: color-primary-5, bg-shade-10, color-brand, etc...
/// @access private
/// @param {map} $map
@mixin _classes($map){
    @each $color, $value in $map {
        $colorFromVar: rgb(var(--#{$color}));
        [class] {
            &.bg-#{$color} { background-color: $colorFromVar; }
            &.color-#{$color} { color: $colorFromVar; }

            &.text-#{$color} { color: $colorFromVar; }
            //! text-{color} is deprecated, it is kept only for translation keys which contain these classes
        }
    }
}


/// Color classes generator
/// ex: color-primary-5, bg-shade-10, color-brand, etc...
/// @access public
@mixin classes() {
    @include _classes($_palette);
    @include _classes($_main);
    @include _classes($_shades);
}

/// Elevations

/// elevation base css - placeholder selector
/// it can only be extended
/// @access public
%elevation {
    z-index: 0;
    position: relative;

    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: inherit;
        z-index: -1;
    }
}

/// elevations classes generator
/// ex: elevation-0 ... elevation-3
/// @access private
/// @param {map} $map
@mixin _elevations($map) {
    @each $elevation, $value in $map {
        [class].#{$elevation} {
            background-color: rgb(var(--elevation-1));
            @if ($elevation != 'elevation-1') {
                @extend %elevation;
                &:before {
                    background-color: rgb(var(--#{$elevation}));
                }
            }
        }
    }
}

/// elevations classes generator
/// ex: elevation-0 ... elevation-3
/// @access public
@mixin elevations() {
    @include _elevations($_elevations);
}

/// single elevation mixin
/// to use with library.elevation(map-get($component, elevation)) in components
/// @access public
@mixin elevation($number) {
    @if ($number and $number != 1) {
        @extend %elevation;
        &:before {
            background-color: rgb(var(--elevation-#{$number}));
        }
    }
}
