body {
    // targeting with body for higher specificity
    #CybotCookiebotDialog {
        color: map-get($modal, color);
    }

    #CybotCookiebotDialogHeader {
        #CybotCookiebotDialogPoweredbyCybot {
            display: none;
        }
    }

    #CybotCookiebotDialog[data-template=slideup] #CybotCookiebotDialogPoweredbyLink {
        width: 100%;
        @include justify-content(center);
    }
    
    @media screen and (min-width: 768px) {
        #CybotCookiebotDialog[data-template=slideup] {

            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            
            #CybotCookiebotDialogPoweredbyLink {
                height: 80px;
                background-image: url('../../../images/brand/red/BItdefender-B.svg');
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;

                img {
                    display: none;
                }
            }
        }
    }

    @media screen and (min-width: 1280px) {
        #CybotCookiebotDialog[data-template=slideup] #CybotCookiebotDialogPoweredbyLink { width: 100px; height: 100px; }
    }
    
    #CybotCookiebotDialogFooter .CybotCookiebotDialogBodyButton {
        @include align-items(center);
        white-space: break-spaces;
    }
}
