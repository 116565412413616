// font-base 16px, all sizez should be deduced from font-size 

h1, h2, h3, h4, h5, h6 {
    font-family: inherit;
    // color: inherit;
    color: theme-color(--shade);
    margin: 0;
    -webkit-font-smoothing: antialiased;
}

// non-mobile
@include breakpoint_from(sm){
    h1, .h1 { font-size: 42px; line-height: 48px;}
    h2, .h2 { font-size: 36px; line-height: 42px }
    h3, .h3 { font-size: 28px; line-height: 34px }
    h4, .h4 { font-size: 24px; line-height: 30px }
}
// mobile
@include breakpoint_to(sm){
    h1, .h1 { font-size: 34px; line-height: 40px; }
    h2, .h2 { font-size: 28px; line-height: 34px; }
    h3, .h3 { font-size: 24px; line-height: 30px; }
    h4, .h4 { font-size: 20px; line-height: 26px }
}

// common mobile + non-mobile
h5, .h5 { font-size: 18px; line-height: 24px }
.b1 { font-size: 16px; line-height: 22px;}
.b2 { font-size: 14px; line-height: 20px;}
.b3 { font-size: 13px; line-height: 18px;}
.c1 { font-size: 12px; line-height: 16px;}
.c2 { font-size: 11px; line-height: 14px;}

p {
    font-size: 1em;
    line-height: 1.375em;
    -webkit-font-smoothing: antialiased;
    color: inherit;
    word-break: inherit;
}

.lead { font-size: 1.15em; }

.strong  { font-weight: 700;}
.medium  { font-weight: 500;}
.regular { font-weight: 400; }
.thin    { font-weight: 300;} // light is used for something else

.line-height-xs {line-height: 1.18em;}
