@import '../../../../ux/style/core/vars';
/// Bitdefender grid component
/// For grid sizes customization see _vars file

$gridColumns: deep-map($grid, columns);
$gridBreakpoints: deep-map($grid, breakpoints);
$gridGutter: map-get($grid, gutter);

/// foreach column append generic flex-col-style
%flex-col-style {
    position: relative;
    min-height: 1px;
    padding-left: $gridGutter;
    padding-right: $gridGutter;
}

.container {
    width: 100%;
    // use pl-1, pr-1 if padding is needed
    // padding-right: $gridGutter;
    // padding-left: $gridGutter;

    @each $key, $value in $gridBreakpoints {
        @include breakpoint-from($key) {
            &.constrained { max-width: deep-map($grid, containers, $key);}
        }
    }
}

.row {
    @include flexbox;
    @include flex-wrap(wrap);
    // flex: 1 1 100%; de verificat, daca .row e intr-un container de tip flex, cu flow column se face 100% pe inaltimea parintelui
    margin-left: -$gridGutter;
    margin-right: -$gridGutter;

    .col, > [class*="col-"] {
        @extend %flex-col-style;
    }

    &.no-gutters {
        margin-left: 0;
        margin-right: 0;
        width: 100%;

        > .col, > [class*="col-"] {
            padding-right: 0;
            padding-left: 0;
        }
    }
    &.spas-gutters {
        margin-left: - $spas;
        margin-right: - $spas;

        > .col, > [class*="col-"] {
            padding-right: $spas * 2;
            padding-left: $spas * 2;
        }
    }

    &.sm-gutters {
        margin-left: - calc($gridGutter / 2);
        margin-right: - calc($gridGutter / 2);

        > .col, > [class*="col-"] {
            padding-right: calc($gridGutter/2);
            padding-left: calc($gridGutter/2);
        }
    }

    &.xs-gutters {
        margin-left: - calc($gridGutter/3);
        margin-right: - calc($gridGutter/3);

        > .col, > [class*="col-"] {
            padding-right: calc($gridGutter/3);
            padding-left: calc($gridGutter/3);
        }
    }

    > .col, > [class*="col-"] {
        &.transitioning {
            transition: all 350ms;
        }
    }
}

.flex-column {
    @include flexbox;
    flex-direction: column;
}

.col {
    @include flex(1 1 0%);
    max-width: 100%;
}

.col-auto {
    @include flex(0 0 auto);
    max-width: 100%;
}

@for $i from 1 through $gridColumns {
    .col-#{$i} {
        @include flex( calc(100%/$gridColumns) * $i);
        max-width: calc(100%/$gridColumns) * $i;
    }
}


// for each breakpoint interval generate specific column width
@each $breakpoint, $map in $gridBreakpoints {

    @include breakpoint-from($breakpoint) {

        @for $i from 1 through $gridColumns {
            .col-#{$breakpoint}-#{$i} {
                @include flex( calc(100%/$gridColumns) * $i);
                max-width: calc(100%/$gridColumns) * $i;
            }
        }

        .col-#{$breakpoint}-auto {
            @include flex(0 0 auto);
            max-width: 100%;
        }

        .col-#{$breakpoint} {
            @include flex(1 1 0%);
            max-width: 100%;
        }
    }
}

/// order depending on breakpoints
.order-first { @include order(-1); }
.order-last {  @include order(13); }

@each $key, $value in $gridBreakpoints {

    @include breakpoint-from($key) {

        .order-#{$key}-first { @include order(-1); }
        .order-#{$key}-last {  @include order(13); }
    }
}