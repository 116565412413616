/// Utility classes - common classes used throughout htmls

.uppercase, .uppercaseFirstLetter::first-letter {
    text-transform: uppercase;
}

.underlined {
    text-decoration: underline;
}

.list-unstyled {
    list-style: inside none;
    margin: 0;
    padding: 0;
}
.italic {
    font-style: italic;
}

/// start text alignment classes
.text-center {
    text-align: center!important;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-middle {
    vertical-align: middle;
}

.text-top {
    vertical-align: text-top;
}

.text-bottom {
    vertical-align: text-bottom;
}

/// end text alignment classes
.wrap {
    white-space: normal;
}
.nowrap {
    white-space: nowrap;
}
.word-break {
    word-break: break-word;
    word-wrap: break-word;
}
.no-overflow {
    overflow: hidden;
}

.ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

hr {
    color: inherit;
    border-style: solid;
    border-color: currentColor;
    border-top-width: 0;
    border-left-width: 0;
    opacity: 0.15;
    margin: 0;

    &.vertical {
        align-self: normal;
    }

    &.dotted {
        border-style: dotted;
    }

    &.dashed {
        border-style: dashed;
    }
}

/// start visibility aka display clases
[class].d-none {
    display: none;
}

[class].d-block {
    display: block !important;
}
[class].d-inline-block {
    display: inline-block !important;
}

[class].d-inline {
    display: inline !important;
}

/// display initial & display none
@each $class, $map in deep-map($grid, breakpoints) {
    @include breakpoint-from($class) {
        [class].d-#{$class}-inline-block {
            display: inline-block;
        }

        [class].d-#{$class}-initial {
            display: initial;
        }

        [class].d-#{$class}-flex {
            @include flexbox();
        }

        [class].d-#{$class}-none {
            display: none;
        }

        [class].d-#{$class}-inline {
            display: inline;
        }
    }
}

.d-initial {
    display: initial !important;
}

/// end visibility clases

/// start flex elements classes
.d-flex {
    @include flexbox;
}

.d-inline-flex {
    display: inline-flex;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-noshrink {
    flex-shrink: 0;
}

.flex-direction-column {
    flex-direction: column!important;
}
.flex-direction-row {
    flex-direction: row!important;
}
.flex-direction-column-reverse {
    flex-direction: column-reverse!important;
}
.justify-space-between {
    justify-content: space-between !important;
}
.justify-space-evenly {
    justify-content: space-evenly !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.align-content-start {
    align-content: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-self-start { align-self: flex-start;}
.align-self-center { align-self: center;}
.align-self-end { align-self: flex-end;}

/// end flex alignment classes
/// start vertical-align classes
/// 
.vertical-align-top {
    vertical-align: top;
}

.vertical-align-middle {
    vertical-align: middle;
}

.vertical-align-bottom {
    vertical-align: bottom;
}

.vertical-align-sub {
    vertical-align: sub;
}

.vertical-align-super {
    vertical-align: super;
}

.vertical-align-baseline {
    vertical-align: baseline;
}

/// end vertical-align classes
/// margin classes - to do: remove important?, increase specificity if needed
@for $i from 0 through 5 {
    .m-#{$i} {
        margin: $i * $spas !important;
    }
    .mt-#{$i} {
        margin-top: $i * $spas !important;
    }
    .ml-#{$i} {
        margin-left: $i * $spas !important;
    }
    .mb-#{$i} {
        margin-bottom: $i * $spas !important;
    }
    .mr-#{$i} {
        margin-right: $i * $spas !important;
    }
    .my-#{$i} {
        margin-top: $i * $spas !important;
        margin-bottom: $i * $spas !important;
    }
    .mx-#{$i} {
        margin-left: $i * $spas !important;
        margin-right: $i * $spas !important;
    }
}
.m-auto {
    margin: auto;
}
.mt-auto {
    margin-top: auto;
}
.mb-auto {
    margin-bottom: auto;
}
.mr-auto {
    margin-right: auto;
}
.ml-auto {
    margin-left: auto;
}
.mx-auto { margin-left: auto; margin-right: auto;}
.my-auto { margin-top: auto; margin-bottom: auto;}

.mb-half-spas {
    margin-bottom: calc($spas / 2) !important;
}
.mt-half-spas {
    margin-top: calc($spas / 2) !important;
}

/// padding classes
@for $i from 0 through 6 {
    .p-#{$i} {
        padding: $i * $spas !important;
    }
    .pt-#{$i} {
        padding-top: $i * $spas !important;
    }
    .pl-#{$i} {
        padding-left: $i * $spas !important;
    }
    .pb-#{$i} {
        padding-bottom: $i * $spas !important;
    }
    .pr-#{$i} {
        padding-right: $i * $spas !important;
    }
    .py-#{$i} {
        padding-top: $i * $spas !important;
        padding-bottom: $i * $spas !important;
    }
    .px-#{$i} {
        padding-left: $i * $spas !important;
        padding-right: $i * $spas !important;
    }
}

/// icons sizes
i,
.icon,
.device-icon,
[class*="connect_icon"] {
    font-size: 1em;

    @for $i from 2 through 6 {
        &.size-#{$i}x {
            font-size: $i * 0.75em;
        }
    }

    &.circled {
        position: relative;
        display: flex !important;
        height: 1em;
        width: 1em;
        align-items: center;
        justify-content: center;

        &:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            border-radius: 50%;
            background-color: #f5f5f5;
        }

        &:before {
            position: relative;
            z-index: 1;
            font-size: 60%;
            color: #1d252d;
        }

        &.primary,
        &.secondary,
        &.warning,
        &.danger,
        &.success {
            &:after {
                background-color: currentColor;
            }

            &:before {
                color: #fff;
            }
        }
    }
}

/// image responsive
.img-fluid {
    width: 100%;
    height: auto;
}

.responsive {
    max-width: 100%;
    height: auto;
}

.w-100 {
    width: 100%;
}

.h-100 {
    height: 100%;
}

.pointer {
    cursor: pointer !important;
}

.cursor-default {
    cursor: default!important;
}

.no-underline {
    text-decoration: none;
}

.text-link {
    color: inherit;
}

figure {
    margin: 0;

    img {
        display: block;
    }

    &.img-responsive {
        position: relative;
        display: block;
        margin: 0;
        width: 100%;
        padding: 0;
        overflow: hidden;

        &[class*="ratio-"] {
            &:before {
                display: block;
                content: "";
                padding-top: 56.25%;
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                height: 100%;
                border: 0;
                transform: translate(-50%, -50%);
            }
        }

        // more wide than tall
        &.ratio-4by3:before {
            padding-top: 75%;
        }
        &.ratio-16by9:before {
            padding-top: 56.25%;
        }
        &.ratio-18by9:before {
            padding-top: 50%;
        }
        &.ratio-21by9:before {
            padding-top: 42.85%;
        }
        &.ratio-24by9:before {
            padding-top: 37.5%
        }

        // more tall than wide
        &.ratio-1by1:before {
            padding-top: 100%;
        }
        &.ratio-3by4:before {
            padding-top: 133%;
        }
        &.ratio-1by1,
        &.ratio-3by4 {
            img {
                width: 100%;
                height: auto;
            }
        }

        // lazy loaded placeholder effect
        &.lazy-loaded {
            background-color: theme-color(--shade-5);
            img {
                white-space: nowrap;
                text-align: center;
                z-index: 1;
                color: transparent;
                &:after {
                    content: "";
                    display: block;
                    width: 20px;
                    height: 100%;
                    background-color: theme-color(--shade-5);
                    top: 0;
                    position: absolute;
                }
            }
            &:after {
                content: "\e925";
                font-family: "connect-font";
                font-size: 42px;
                color: theme-color(--shade-20);
                text-align: center;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                transform: translateY(-50%);
                padding: 24px;
            }
            &:before, &:after { background-color: inherit;}
        }

    }
}

[class].color-inherit { color: inherit; }
[class].bg-inherit { background-color: inherit; }
[class].bg-none { background-color: transparent; }
