html, body {
    position: relative;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

body {
    font-family: map-get($theme, font-family);
    font-size: map-get($theme, font-size);
    line-height: map-get($theme, line-height);
    color: map-get($theme, color);
    background-color: map-get($theme, background);
    background-image: map-get($theme, background-image);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body * {
    box-sizing: border-box;
}

a {
    color: theme-color(--primary);
    cursor: pointer;
    &:hover, &:focus {
        text-decoration: underline;
        outline: none;
    }
}
