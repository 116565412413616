.flex-list {
    list-style: inside none;
    padding: 0;
    margin: 0;
    @include flexbox;
    @include flex-flow(column nowrap);

    &.column {
        hr {
            flex: 1 1 100%;
            margin: $spas * 2 0;
        }
    }

    &.row {
        @include flex-flow(row nowrap);

        hr {
            height: 100%;
            margin: 0 $spas * 2;
        }
    }

    &.boxed {
        border: 1px solid;
        border-color: inherit;
    }

    hr {
        opacity: 1;
    }

    /// list types
    @each $class, $map in map-get($flex-list, types) {
        &.#{$class} {
            border-color: map-get($map, divider);

            &.highlights {
                .list-item:not(.list-item-footer) {
                    &:hover,
                    &.active,
                    &:focus {
                        color: map-get($map, highlight-color);
                        background-color: map-get($map, highlight-background);
                        background-image: map-get($map, highlight-gradient);
                        outline: none;
                    }
                }
            }
        }
    }

    a.list-item {
        cursor: pointer;
        color: inherit;

        a.active {
            color: theme-color(--primary);
        }
    }

    &.unbordered {
        .list-item + .list-item:not(.list-item-separator) {
            border-top-width: 0;
            border-top-style: none;
        }
    }

    .list-item + .list-item {
        border-top-width: 1px;
        border-top-style: solid;
    }

    .list-item {
        margin: 0;
        @include flexbox;
        @include flex-flow(row nowrap);
        align-items: center;
        text-decoration: none;
        text-align: left;
        transition: background-color 200ms ease-out, color 150ms ease-in;
        line-height: map-get($theme, line-height);
        line-height: 1.375em;
        border-color: inherit;

        &.disabled-actions {
            pointer-events: none;
            opacity: 0.5;
        }

        &.column {
            flex-flow: column nowrap;
            align-items: flex-start;
        }

        &.list-item-separator {
            padding-top: 0;
            padding-bottom: 0;
            border-bottom: none;
            border-top-width: 1px;
            border-top-style: solid;

            &:hover {
                background: none !important;
            }
        }

        .title {
            display: block;
            line-height: 1.15em;
            margin: 0;
        }

        .thumb,
        .icon {
            line-height: inherit;
        }

        .thumb {
            font-size: 48px;
            img {
                font-size: 14px;
            }
        }

        .details {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: calc(1 * 100%/12);
            line-height: inherit;

            /// span-1 si span-12 nu au sens
            /// span-1 e defaultul (se intinde atat cat poate) si span-12 ar insemna ca nu mai are alte elemente pe langa deci ar fi tot defaultul
            @for $i from 2 through 11 {
                &.span-#{$i} {
                    flex-grow: $i;
                    flex-basis: calc($i * 100%/12);
                }
            }

            .title {
                font-weight: 500;

                & + p {
                    line-height: 1.3;
                }
            }

            p {
                margin: 0;
                line-height: inherit;
            }
        }

        &.disabled {
            background-color: theme-color(--shade-5);
            opacity: 0.9;
            cursor: default;
        }

        &.unbordered {
            border: none;
        }
    }
}

/// flex-list sizes

.flex-list {
    // default
    .list-item {
        padding: $spas;

        .details {
            padding: $spas;
        }

        thumb,
        .thumb,
        .icon {
            margin: $spas;
        }
    }

    @include breakpoint_from(md) {
        &.list-lg {
            .list-item {
                padding: $spas * 2;

                .icon {
                    font-size: 1.2em;
                }

                &.column {
                    padding: $spas * 3 0;
                }
            }
        }

        &.list-sm {
            .list-item {
                padding: calc($spas / 2);

                .details {
                    padding: calc($spas / 2);
                }

                thumb,
                .thumb,
                .icon {
                    margin: calc($spas / 2);
                }
            }
        }
    }
}

/// list of cards
.flex-list.cards-list {
    .card:not(:first-child) {
        margin-top: 2 * $spas;
    }
}
