.toolbar {
    font-size: 1rem;
    padding: 0 map-get($toolbar, padding);

    /// toolbar color, background and text color from toolbar.types
    @each $class, $toolbar in map-get($toolbar, types) {
        &.#{$class} {
            background: map-get($toolbar, background);
            background-image: map-get($toolbar, background-gradient);
            color: map-get($toolbar, color);
            @include library.elevation(map-get($toolbar, elevation));
        }
    }

    @include flexbox;
    flex-flow: row nowrap;
    height: map-get($toolbar, heights, xs);

    /// toolbar height, from toolbar.heights, for each breakpoint speciffied in _vars file
    @each $bp, $height in map-get($toolbar, heights) {
        @include breakpoint_from($bp) {
            height: $height;
        }
    }


    .toolbar-logo {
        width: 100%;
        min-width: 130px;
        max-width: 240px;

        img {
            width: 100%;
        }
    }

    .icon {
        font-size: 21px;
    }

    .toolbar-content {
        @include flexbox;
        @include flex-grow(1);
        @include flex-basis(auto);        
        height: 100%;
        align-items: center;
        padding: 0 map-get($toolbar, padding);

        &.left {
            justify-content: flex-start;
            text-align: left;
            @include flex-shrink(1);
            overflow-x: auto;
        }

        &.right {
            justify-content: flex-end;
            text-align: right;
            @include flex-shrink(0);
        }

        &.center {
            justify-content: center;
            text-align: center;
            @include flex-shrink(1);
        }   
    }   

  
}
