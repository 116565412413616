/// App custom styling starts here

main {
    height: 100%;
}

app-support-article .style-article {
    margin-bottom: 64px;

    h4 {
        font-size: 24px;
        margin-top: 40px;
    }

    .img-responsive {
        margin-bottom: 36px;
        display: block;
        max-width: 100%;
        height: auto;
    }
}

.logo-text {
    font-size: 21px;
    font-weight: bold;
}

.logo {
    &.hp-logo {
        .manufacturer {
            max-width: 165px;
        }

    }
}
