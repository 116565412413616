/// Butons style
$btn-focus-shadow: 0 0 0 2px inset;

%button {
    position: relative;
    font-family: inherit;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    color: inherit;
    white-space: nowrap; // maybe this should not be default behaviour
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    padding: 0;

    > * { pointer-events: none;}
}

button {   
    @extend %button;
    background: transparent;
    font-size: inherit;

    &::-moz-focus-inner {
        border: 0;
    }
}

.btn {
    @extend %button;    
    font-weight: 500;
    text-decoration: none;
    background-color: transparent;    
    border-radius: map-get($button, border-radius);

    [class*="connect_icon"],
    .device-icon { vertical-align: middle; }

    &.ellipsis {
        max-width: 100%;
    }

    // button sizes
    @each $class, $size in map-get($button, size) {
        &.#{$class} {
            font-size: map-get($size, font-size);
            line-height: map-get($size, line-height);
            padding: calc((map-get($size, height) - map-get($size, line-height)) / 2) map-get($button, padding);

            &.pill { border-radius: map-get($size, height);}

            // states min-width, is overwritten by icon button's min-width, this css needs to remain this way
            &.solid, &.outline, &.discrete {
                min-width: 2 * map-get($size, height);
            }

            &.icon {
                min-width: map-get($size, height);
                min-height: map-get($size, height);
                padding: 0;
            }
        }
    }

    // button label
    .btn-label {
        position: relative;
        width: 100%;
    }
    
}

// disabled button, when disabled all button types look the same
.btn {
    &[disabled], &.disabled{
        pointer-events: none;
        background-color: theme-color(--shade-5) !important;
        color: theme-color(--shade-60) !important;
        box-shadow: none;
    }
}

/// solid button style, and it's variations
.solid {
    color: theme-color(--light);

    &:before {
        content: "";
        display: block;
        position: absolute;
        left:0; right:0; top:0; bottom: 0;
        background: theme-color(--dark);
        opacity: 0;
        border-radius: inherit;
    }

    @each $type, $color in map-get($button, type) {
        &.#{$type} {
            background-color: theme-color(--#{$color});

            &:hover:before { opacity: 0.2;}
            &:focus { box-shadow: $btn-focus-shadow theme-color(--#{$color}-20); }
            &:active:before, .clicked { opacity: 0.4; box-shadow: none;}
        }
    }
}

/// outline button style, and it's variations
.outline  {
    box-shadow: 0 0 0 1px inset;

    @each $type, $color in map-get($button, type) {
        &.#{$type} {
            color: theme-color(--#{$color});

            &:hover {  background-color: theme-color(--#{$color}, 0.1);}
            &:focus {  box-shadow: $btn-focus-shadow theme-color(--#{$color}-20); }
            &:active, .clicked { background-color: theme-color(--#{$color}, 0.2); }
        }
    }
}

/// discrete button style, and it's variations
.discrete  {
    @each $type, $color in map-get($button, type) {
        &.#{$type} {
            color: theme-color(--#{$color});

            &:hover {  background-color: theme-color(--#{$color}, 0.1);}
            &:active, &.clicked { background-color: theme-color(--#{$color}, 0.2); box-shadow: none; }
            &:focus {  border-color: theme-color(--#{$color}-20); box-shadow: $btn-focus-shadow theme-color(--#{$color}-20); }
        }
    }
}

/// clear button style
.btn.clear:not(.link) {
    &:focus:not(.clicked) { box-shadow: $btn-focus-shadow theme-color(--primary-20); }
}

/// link button style, and it's variations
.btn.link {
    padding: initial;
    line-height: inherit;

    &:focus, &:hover { text-decoration: underline;}

    i, [class*=connect_icon] { text-decoration: none; display: inline-block;}

    @each $type, $color in map-get($button, type) {
        &.#{$type} {
            color: theme-color(--#{$color});
        }
    }
}

// loading state for buttons waiting on requests
.btn.loading {
    pointer-events: none;
    user-select: none;

    .btn-label { color: transparent; }

    &:after {      
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -0.7em;
        margin-left: -0.7em;
        font-size: 1.1em;
        border-width: 0.2em;
        border-style: solid;
        border-top-color: transparent !important;
        border-radius: 50%;
        width: 1em;
        height: 1em;
        -webkit-animation: spin 0.7s linear infinite;
        animation: spin 0.7s linear infinite;
        box-sizing: content-box;
    }        
}