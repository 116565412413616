.card {
    @include flexbox;
    @include flex-flow(column nowrap);
    box-sizing: border-box;
    position: relative;
    font-size: 1em;
    border-radius: map-get($card, border-radius);
    border-color: map-get($card, divider);
    outline: none;

    * { box-sizing: border-box;}

    @each $type, $card in map-get($card, types) {
        &.#{$type} {
            @include library.elevation(map-get($card, elevation));
            background-color: map-get($card, background);
            background-image: map-get($card, background-gradient);
            box-shadow: map-get($card, shadow);
        }
    }

    &.bordered {
        border-width: 1px;
        border-style: solid;
    }

    &.disabled {
        opacity: 0.6;
        pointer-events: none;
        box-shadow: none;
    }

    &.gray {
        background-color: map-get($card, types, empty, background);
        background-image: map-get($card, types, empty, background-gradient);
    }
    // in time we should lose empty and gray states, when one of thm is needed apropiate bg colors should be used

    /// any direct child of card (card- header, body or footer) can have an overwriteable empty-state color
    > .empty {
        background-color: map-get($card, types, empty, background);
        background-image: map-get($card, types, empty, background-gradient);
    }

    // card elements spacing
    .card-header, .card-body, .card-footer {
        padding-left: map-get($card, padding);
        padding-right: map-get($card, padding);
        border-color: inherit;
    }

    .card-header,
    .card-footer.bordered,
    .card-footer.only-child,
    .card-body.only-child,
    .card-header.bordered + .card-body  {
        padding-top: map-get($card, padding);
    }

    .card-header.bordered,
    .card-header.only-child,
    .card-body,
    .card-footer {
        padding-bottom: map-get($card, padding);
    }

    // each card element base styling
    .card-header {
        @include flexbox;
        @include flex-flow(row nowrap);
        @include flex-shrink(0);
        @include align-items(center);
        position: relative;

        &.bordered {
            border-bottom-style: solid;
            border-bottom-width: 1px;
        }

        &.flex-column {
            @include flex-direction(column);
        }
    }

    .card-body {
        @include flex-grow(1);
        @include flex-shrink(1);
        @include flex-basis(100%);
        // ! or auto, but never 0
        padding-top: $spas;

        &.empty {
            @include flexbox;
            @include flex-flow(column nowrap);
            @include align-items(center);
            @include flex-basis(auto);
        }

        .card-text {
            &:not(:first-child) { margin-top: 8px;}
            &:last-child { margin-bottom: 0;}
        }

        &.bordered {
            border-bottom-style: solid;
            border-bottom-width: 1px;
        }
    }

    .card-footer {
        @include flex-shrink(0);
        &.bordered {
            border-top-style: solid;
            border-top-width: 1px;
        }
    }

    .top-shadowed, .bottom-shadowed {
        position: relative;

        &:after {
            content: "";
            display: block;
            left: 0;        
            right: 0;
            position: absolute;
            height: 7px;
            opacity: 0.25;
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,e5e5e5+100&0.5+0,0+100 */
            background: -moz-linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(229,229,229,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, rgba(0,0,0,0.5) 0%,rgba(229,229,229,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, rgba(0,0,0,0.5) 0%,rgba(229,229,229,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#00e5e5e5',GradientType=0 ); /* IE6-9 */
            z-index: 1;
        }
    }

    .top-shadowed:after {
        top: 0;
    }

    .bottom-shadowed:after {
        bottom: 0;
        transform: rotate(180deg);
    }

    .top-bottom-shadowed {
        position: relative;
        
        &:after {
            content: "";
            display: block;
            top: 0;
            bottom: 0;
            left: -7px;        
            right: -7px;
            position: absolute;
            box-shadow: inset 0 0 8px 0 rgba(0, 0, 0, 0.2);
            pointer-events: none;
        }
    }

    .card-thumb {
        font-size: 32px;
        min-width: 1em;
        min-height: 1em;;
        flex-shrink: 0;       
        @include flexbox();
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        position: relative;

        figure, img, .profile-picture { font-size: 12px; }
    }

    .text {
        font-weight: normal;
        margin: 0;
    }

    .card-details {
        // flex: 1 1 100%;
        overflow: hidden;
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: 30%;
        position: relative;
    } 

    .card-actions {
        white-space: nowrap;
        @include flexbox;
        @include flex-flow(row nowrap);
        align-items: center;     
    }

    @include breakpoint-to(sm){
        .card-header {
            flex-wrap: wrap;
        }
    }
}

// clickable card
.card.clickable-card {
    &:hover, &:focus {
        cursor: pointer;
        outline: none;        
        border-color: theme-color(--primary);
        background-color: theme-color(--primary-5);
    }
}
